@tailwind base
@tailwind components
@tailwind utilities

@import style/mixins
@import style/variables
@import style/admin
@import style/stocks
@import style/zone-colors

@import '@ng-select/ng-select/themes/default.theme.css'

:root
  --first-shift-bg: #f2f2f2
  --second-shift-bg: #e6e6e6
  --third-shift-bg: #bdbdbd

*
  box-sizing: border-box

img
  display: unset

$sizes: ( "1": 0.3em, "2": 0.5em, "3": 0.7em, "4": 1em, "5": 1.25em )

/* Customs CSS */
html
  height: 100%
  --color-default: #f4f4f4
  --color-default-hover: #c8c8c8
  --color-danger: #fa6446
  --color-danger-hover: #f93a14
  --color-success: #34a835
  --color-success-hover: #107d11
  --color-info: #007ad9
  --color-info-hover: #116fbf
  --mdc-filled-text-field-container-color: white

body
  margin: 0px
  +app-font-regular()

a.disabled
  cursor: not-allowed
a
  cursor: pointer

/** Color **/
.color
  &-yellow
    color: $yellow
  &-dark-green
    color: $dark-green
  &-red
    color: $red

/** Background Color **/
.bg
  &-purple
    background: var(--sidebar-header-bg, $purple) !important
  &-yellow
    background: $yellow !important

.hidden
  display: none

.pcr
  &-toast-sync
    text-align: center
    font-size: 10px
    &-content
      width: 100%
      display: block
      font-size: 12px
      margin-top: 10px
      margin-bottom: 10px
    &-action
      width: 100%
      display: block
  &-sidebar
    .ui-inputtext
      background-color: $grey
      color: white
      width: 176px
      &::placeholder
        color: white
      &:hover
        border-color: white !important
    .ui-dropdown
      &:hover
        border-color: white !important
    .ui-dropdown-label
      background-color: $grey
      color: white
    .ui-dropdown-trigger
      background-color: $grey !important
      span
        color: white
    &-body-container-dropdown-black
      .ui-dropdown-label
        background-color: $black
        color: white
      .ui-dropdown-trigger
        background-color: $black !important
        span
          color: white
  .ui-dialog-titlebar
    background-color: $red !important
    color: white !important
    text-align: center
    +app-font-regular()
  .ui-dialog-content
    +app-font-regular()
  .ui-button:focus
    box-shadow: 0 0 0 0.2em #ddf0ff !important
    +app-font-regular()

.ui-overlaypanel-content
  padding: 3px !important
  margin-bottom: 10px

.p-overlaypanel .p-overlaypanel-content
  padding: 5px !important

.p-sidebar
  .p-sidebar-content
    height: 100%
  .p-sidebar-content, .p-sidebar-header
    padding: 0px !important

.free
  font-size: 1.8em !important
  color: #C5CF00
  cursor: pointer
  margin-right: 5px

.closed
  font-size: 1.8em !important
  color: tomato
  margin-right: 5px

.overlay-lock
  position: absolute
  top: 0
  left: 0
  height: 100%
  cursor: not-allowed
  z-index: 199

.relative
  position: relative

.cdk
  &-drag
    &-preview
      box-sizing: border-box
      border-radius: 4px
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12)
      &-placeholder
        opacity: 0
        &-animating
          transition: transform 250ms cubic-bezier(0, 0, 0.2, 1)
        &-disabled
          opacity: 0.65
    &-drop
      &-list-dragging *:not(.cdk-drag-placeholder)
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1)

.cdk-drag-disabled
  opacity: 1 !important

.cdk-drag-preview
  margin: 0 !important

.ui-menu
  padding: 10px 0 !important
  min-width: 250px

.ui-menuitem-icon
  width: 30px !important
  height: 30px !important
  display: inline-block

body .ui-contextmenu .ui-menuitem-link
  padding: 0.1em 0 !important

.timeline
  &-rule-detail-container
    &-date
      +app-font-semibold()
    &-hours-bloc
      +app-font-lightpro()
  &-date-range
    +app-font-semibold()

.icon-button
  cursor: pointer
  &:disabled
    cursor: not-allowed
    border: none
    background-color: transparent
    font-size: inherit

.center
  text-align: center

.d-flex
  display: flex !important

.d-block
  display: block !important

.ui-calendar
  .ui-button
    background-color: $green !important
    border-color: $green !important

input[type=datetime-local]
  padding: 0.1em 0.2em
  +app-font-regular()
  font-size: inherit
  opacity: 0.8

.visually-hidden
  position: absolute !important
  height: 1px
  width: 1px
  padding: 0
  margin: 0
  overflow: hidden
  clip: rect(1px, 1px, 1px, 1px)
  margin: -1px

.stocks__label
  font-weight: bolder

.graph-filters
  display: flex
  flex-flow: row wrap
  justify-content: space-between

.graph-checkbox-group
  padding: 0.5em 1em
  display: flex
  flex-flow: row wrap
  align-items: center
  background: whitesmoke
  flex: 1 0 200px
  &:nth-child(2n)
    background: darken(whitesmoke, 3%)
    button.factory
      border: none
      background: none
      padding: 2px
      font: inherit
      cursor: pointer
      display: flex
      flex: 0 0 150px
      flex-flow: row wrap
      font-weight: bold
      opacity: 0.8
      text-align: left
      align-items: center
      color: white
      text-shadow: 2px 2px 6px rgba(0, 0, 0, .5), -2px -2px 6px rgba(0, 0, 0, .5)
      i
        display: inline-block
    .checkboxes
      flex: 1 1 auto
      display: flex
      flex-flow: row wrap
      justify-content: flex-end
      .checkbox
        padding-right: 0.4em
        label
          display: inline-block
          margin-left: 0.3em
          cursor: pointer

.ui-tabview, .ui-tabview.ui-tabview-panel
  height: 100%

body .ui-tabview .ui-tabview-panels,
body .ui-tabview .ui-tabview-panels .ui-tabview-panel
  min-height: 100% !important
  margin-bottom: 1em

.ui-multiselect
  width: 100% !important

.ui-multiselect-filter-icon
  color: white !important

.ui-multiselect-item
  &.ui-state-highlight
    background-color: transparent !important
    color: $dark-grey !important

.ui-chkbox-box
  &.ui-state-active
    background-color: map-get($zone-colors, 'planification') !important
    border-color: map-get($zone-colors, 'planification') !important

a
  &.btn-info
    &:hover
      color: white

button
  cursor: pointer
  &:disabled
    cursor: not-allowed

.agropilt-btn
  all: unset
  --background-color: var(--color-default)
  --background-color-hover: var(--color-default-hover)
  --text-color: black
  font-family: "Open Sans", "Helvetica Neue", sans-serif
  display: inline-flex
  flex-flow: row wrap
  align-items: center
  border-radius: 3px
  background-color: var(--background-color)
  color: var(--text-color)
  transition: 0.25s ease
  cursor: pointer
  border: 1px solid var(--background-color)
  padding: 0.4em .7em
  font-size: 14px
  i, img
    display: inline-block
    margin-right: 0.4em
  &.square
    border-radius: 0
  &.center
    margin-left: auto
    margin-right: auto
    display: flex
  &:not(:disabled):hover
    border: 1px solid var(--background-color-hover)
    background-color: var(--background-color-hover)
  &:disabled
    cursor: not-allowed
    opacity: 0.5
  &-danger
    --background-color: var(--color-danger)
    --background-color-hover: var(--color-danger-hover)
    --text-color: white
  &-success
    --background-color: var(--color-success)
    --background-color-hover: var(--color-success-hover)
    --text-color: white
  &-info
    --background-color: var(--color-info)
    --background-color-hover: var(--color-info-hover)
    --text-color: white

@each $index, $size in $sizes
  .m-#{$index}
    margin: $size
  .m-t-#{$index}
    margin-top: $size
  .m-b-#{$index}
    margin-bottom: $size
  .m-r-#{$index}
    margin-right: $size
  .m-l-#{$index}
    margin-left: $size
  .p-#{$index}
    padding: $size
  .p-t-#{$index}
    padding-top: $size
  .p-b-#{$index}
    padding-bottom: $size
  .p-r-#{$index}
    padding-right: $size
  .p-l-#{$index}
    padding-left: $size

.m-x-a
  margin-left: auto !important
  margin-right: auto !important

@keyframes marker-enter
  from
    transform: scaleY(0) translate(-50%)
    opacity: 0

.pc-radio-marker
  $color: map-get($zone-colors, "simulation")
  position: absolute
  top: 33px
  left: var(--left)
  transform-origin: 50% 0
  transform: scaleY(1) translate(-50%)
  height: calc(100% - 33px)
  cursor: pointer
  z-index: 3
  width: 0
  border: 2px #636363 dotted
  animation: .2s marker-enter both

.trucks-assignment-wrapper
  overflow: scroll
  &.-no-scroll-x::-webkit-scrollbar
    display: none
  &.-no-scroll-x
    -ms-overflow-style: none
  &.-no-scroll-y::-webkit-scrollbar
    overflow-y: hidden
  &.-no-scroll-x
    -ms-overflow-style: none
  &.panel
    height: calc((100vh - 118px) / 2)

.ao-required
  color: red
  font-weight: bold
  margin-left: 3px
  font-size: 1em
.mat-icon > .ao-required
  position: absolute
.mat-icon:has(.ao-required)
  position: relative

.mat-select-arrow-wrapper
  transform: translateY(0%) !important

.cdk-virtual-scroll-content-wrapper
  li:first-child
    margin-top: 0 !important

// Manipulate initial background color
.mdc-text-field--filled:not(.mdc-text-field--disabled)
  background-color: white !important

// Manipulate background color on hover and focus
.mat-mdc-form-field-focus-overlay
  background-color: transparent !important

/**
 Sass classes for priority colors
 **/
.bg-priority
  &-d-2
    background: var(--day-minus-two)
  &-d-1
    background: var(--day-minus-one)
  &-d
    background: var(--day)
  &-d1
    background: var(--day-plus-one)
  &-d2
    background: var(--day-plus-two)
  &-d3
    background: var(--day-plus-three)

#sidebar-grid
  grid-template-columns: 40px calc(100vw - 40px)

#table-grid
  display: grid
  grid-template-rows: calc(50vh - 30px) calc(50vh - 30px)
  overflow: hidden

.modal-box
  #grid-details
    grid-template-columns: auto 1fr

input.input.input-bordered
  &.ng-touched.ng-invalid.ng-dirty
    border-color: red
    outline-color: red

.ng-select.ng-select-single .ng-select-container, .ng-select.ng-select-multiple .ng-select-container
  min-height: 48px !important
  border-radius: 0.5rem !important

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder
  padding-top: 8px